import React from 'react';
import Slider from 'react-slick'; // Importing react-slick slider
import {
    ShowcaseContainer,
    CarouselItem,
    SliderContainer,
    HeadingContainer,
    ArrowButton
} from './CarouselShowcase.styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SectionHeading, Spacer } from '../common/Common.styles';


const CarouselShowcase = ({ title, items }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return (
        <ShowcaseContainer>
            <HeadingContainer>
            <SectionHeading>{title}</SectionHeading>
            </HeadingContainer>
            <Spacer size={2} />
            <SliderContainer>
                <Slider {...settings}>
                    {items.map((item, index) => (
                        <CarouselItem key={index}>
                            <img src={item.image} alt={item.alt} />
                        </CarouselItem>
                    ))}
                </Slider>
            </SliderContainer>
        </ShowcaseContainer>
    );
};

// Custom Arrow Components
const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <ArrowButton className={className} onClick={onClick}>
            <span aria-hidden="true">›</span>
        </ArrowButton>
    );
};

const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <ArrowButton className={className} onClick={onClick}>
            <span aria-hidden="true">‹</span>
        </ArrowButton>
    );
};

export default CarouselShowcase;