import styled from 'styled-components';

// Main container for the showcase
export const ShowcaseContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 3rem 2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

// Individual item in the carousel with a fixed width of 200px
export const CarouselItem = styled.div`
  height: 220px;
  flex: none;
  padding: 0 1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  outline: none;
  border: none;
`;

// Container for the slider
export const SliderContainer = styled.div`
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    overflow: hidden;

    .slick-arrow {
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
        z-index: 1;
        transition: all 0.3s ease;

        span{
            color: var(--primary-color-green) !important;
        }

        &:hover {
            span{
                color: var(--primary-color-green) !important;
            }
        }

        &::before {
            display: none;
        }
    }

    .slick-prev {
        left: -25px;
    }

    .slick-next {
        right: -25px;
    }
`;

// Custom arrow styles
export const ArrowButton = styled.div`
    font-size: 32px;
    color: #2b545a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
`;

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;