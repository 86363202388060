/* global Grnhse */

import React, { useRef, useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoadingContainer, FloatingButton
} from "../components/common/Common.styles";
import { useJobDetails } from "../hooks/useJobsDetails";
import BarLoader from 'react-spinners/BarLoader';
import useLanguage from '../hooks/useLanguage';
import SEO from '../components/SEO/SEOComponent';
import DetailsHero from '../components/HeroSection/DetailsHero';

// SVG Component for the Floating Button - memoized
const BackIcon = memo(() => (
  <svg fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 12H5"></path>
    <path d="m12 19-7-7 7-7"></path>
  </svg>
));

// Category mappings - moved outside component
const CATEGORY_MAPPINGS = {
  Admin: ['4005227008', '4005228008', '4005229008'],
  Plant: ['4005230008'],
  Retail: ['4005231008']
};

export default function JobDetails() {
  const { t } = useTranslation();
  const { job, loading, error } = useJobDetails();
  const formRef = useRef(null);
  const { language } = useLanguage();
  const [isLoading, setIsLoading] = useState(true);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [greenhouseError, setGreenhouseError] = useState('');

  // Memoized getCategory function
  const getCategory = useCallback((categoryId) => {
    if (!categoryId) return 'resources.categories.unknown';

    const categoryIdStr = categoryId.toString();
    const foundKey = Object.entries(CATEGORY_MAPPINGS).find(([, ids]) => ids.includes(categoryIdStr));
    return foundKey ? `resources.categories.${foundKey[0]}` : 'resources.categories.unknown';
  }, []);

  // Memoized values
  const board = useMemo(() => language === 'fr' ? 'bainmagique' : 'bathfitterdistributinginc', [language]);
  const gh_jid = useMemo(() => new URLSearchParams(window.location.search).get('gh_jid'), []);

  // Memoized redirect function
  const redirectToCareers = useCallback(() => {
    window.location.href = language === 'fr' ? '/fr/carrieres' : '/en/careers';
  }, [language]);

  // Greenhouse script loading
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://boards.greenhouse.io/embed/job_board/js?for=${board}`;

    // Error handling function
    const handleGreenhouseError = (errorMessage) => {
      console.error(errorMessage);
      // Set a small timeout before reloading to allow the console error to be logged
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    };
    const handleScriptLoad = () => {
      try {
        setIsLoading(true);

        const timeoutId = setTimeout(() => {
          if (typeof Grnhse !== 'undefined' && Grnhse.Iframe) {
            try {
              Grnhse.Iframe.load(gh_jid);
            } catch (err) {
              handleGreenhouseError(`Error loading job application form: ${err.message}`);
            }
          } else {
            handleGreenhouseError('Greenhouse iframe failed to load');
          }
        }, 1000);

        return () => clearTimeout(timeoutId);
      } catch (err) {
        handleGreenhouseError(`Error loading job application: ${err.message}`);
      }
    };

    // Message event handler
    const handleMessage = (event) => {
      if (event.origin.includes('greenhouse.io')) {
        if (event.data && typeof event.data === 'string' &&
          (event.data.includes('401 Unauthorized') || event.data.includes('error'))) {
          handleGreenhouseError('Authentication error with job application system. Please try again later.');
        }
      }
    };

    // Network error handling
    const originalFetch = window.fetch;
    window.fetch = function (url, options) {
      return originalFetch(url, options)
        .then(response => {
          if (url.includes('greenhouse.io') && !response.ok) {
            handleGreenhouseError(`Error ${response.status}: Authentication error with job application system.`);
          }
          return response;
        })
        .catch(error => {
          if (url.includes('greenhouse.io')) {
            handleGreenhouseError('Network error with job application system. Please check your connection.');
          }
          throw error;
        });
    };

    window.addEventListener('message', handleMessage);
    script.addEventListener('load', handleScriptLoad);
    document.body.appendChild(script);

    // Fallback timeout
    const fallbackTimeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 10000);

    return () => {
      script.removeEventListener('load', handleScriptLoad);
      window.removeEventListener('message', handleMessage);

      // Restore original fetch
      window.fetch = originalFetch;

      clearTimeout(fallbackTimeoutId);

      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [board, gh_jid]);

  // Iframe load monitoring
  useEffect(() => {
    const checkIframeLoaded = () => {
      const iframe = document.querySelector('#grnhse_app iframe');
      if (iframe) {
        setIframeLoaded(true);
        setIsLoading(false);
        observer.disconnect();
      }
    };

    const observer = new MutationObserver(checkIframeLoaded);

    if (formRef.current) {
      observer.observe(formRef.current, { childList: true, subtree: true });
      checkIframeLoaded(); // Check immediately
    }

    const timeoutId = setTimeout(() => {
      if (!iframeLoaded) {
        setIsLoading(false);
        console.warn('Greenhouse iframe load timeout reached');
      }
    }, 2000);

    return () => {
      observer.disconnect();
      clearTimeout(timeoutId);
    };
  }, [iframeLoaded]);

  // Memoized job details
  const jobDetails = useMemo(() => {
    if (!job) return null;

    const category = t(getCategory(job.departments[0]?.parent_id));
    const department = t(`resources.departments.id_${job.departments[0]?.id}`, job.departments[0]?.name);
    const location = t(`resources.locations.${job?.location?.name.replace(/[\s,]+/g, '')}`, job?.location?.name);

    return { category, department, location };
  }, [job, t, getCategory]);

  // Loading and error states
  if (loading) return <div>{t('jobDetailsPage.loading')}</div>;
  if (error) return <div role="alert">{t('jobDetailsPage.error', { error: error.message })}</div>;
  if (!job) return <div role="alert">{t('jobDetailsPage.notFound')}</div>;

  return (
    <>
      <SEO
        title={`${job.title} | ${t('seo.common.organization.name')}`}
        description={`${job.title} - ${jobDetails.department} ${jobDetails.location}`}
        image="https://careers.bathfitter.com/Media/Default/careers/careers-hero.jpg"
        url={`https://careers.bathfitter.com/job-details?gh_jid=${job.id}`}
        author={t('seo.common.author')}
        publishedAt={job.updated_at}
        type="JobPosting"
        schemaType="JobPosting"
        schema={{
          '@context': 'https://schema.org',
          '@type': 'JobPosting',
          title: job.title,
          description: job.content,
          datePosted: job.updated_at,
          validThrough: job.updated_at,
          employmentType: jobDetails.category,
          hiringOrganization: {
            '@type': 'Organization',
            name: t('seo.common.organization.name'),
            sameAs: 'https://careers.bathfitter.com',
            logo: 'https://careers.bathfitter.com/logo.png'
          },
          jobLocation: {
            '@type': 'Place',
            address: {
              '@type': 'PostalAddress',
              addressLocality: job?.location?.name,
              addressRegion: job?.location?.name.split(',')[1]?.trim(),
              addressCountry: job?.location?.name.split(',')[2]?.trim()
            }
          },
          baseSalary: {
            '@type': 'MonetaryAmount',
            currency: job?.location?.name.includes('Canada') ? 'CAD' : 'USD',
            value: {
              '@type': 'QuantitativeValue',
              unitText: 'YEAR',
              minValue: job.salary_min || '',
              maxValue: job.salary_max || ''
            }
          },
          industry: t('seo.common.organization.description'),
          occupationalCategory: jobDetails.department,
          qualifications: job.qualifications || '',
          responsibilities: job.responsibilities || '',
          skills: job.required_skills || '',
          department: {
            '@type': 'Organization',
            name: jobDetails.department
          },
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `https://careers.bathfitter.com/job-details?gh_jid=${job.id}`,
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: t('header.home'),
                  item: 'https://careers.bathfitter.com'
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: t('header.careers'),
                  item: 'https://careers.bathfitter.com/careers'
                },
                {
                  '@type': 'ListItem',
                  position: 3,
                  name: job.title,
                  item: `https://careers.bathfitter.com/job-details?gh_jid=${job.id}`
                }
              ]
            }
          },
          potentialAction: {
            '@type': 'ApplyAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: `https://careers.bathfitter.com/job-details?gh_jid=${job.id}#grnhse_app`,
              actionPlatform: [
                'http://schema.org/DesktopWebPlatform',
                'http://schema.org/MobileWebPlatform'
              ]
            }
          }
        }}
      />

      <FloatingButton onClick={redirectToCareers}>
        <BackIcon />
      </FloatingButton>

      <LoadingContainer $loading={isLoading}>
        <BarLoader color="#40c1ac" />
      </LoadingContainer>

      <DetailsHero
        desktopImage="https://cdn01.onelogin.com/images/brands/backgrounds/login/d10e16a95980543a58ed32e1bad443f483267712.jpg?1605031090"
        mobileImage="https://cdn01.onelogin.com/images/brands/backgrounds/login/d10e16a95980543a58ed32e1bad443f483267712.jpg?1605031090"
        jobname={job?.title}
        dep={jobDetails.department}
        cat={jobDetails.category}
        loc={jobDetails.location}
        maxWidth="465px"
      />

      <div ref={formRef} id="grnhse_app">
        {greenhouseError && (
          // This will rarely be shown since we're reloading the page when an error occurs,
          // but keeping it as a fallback in case the reload doesn't happen immediately
          <div className="loading-container">
            <BarLoader color="#40c1ac" />
            <p>{t('jobDetailsPage.loading')}</p>
          </div>
        )}
      </div>
    </>
  );
}